
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { DattaConfig } from '../app-config';
import { map } from 'rxjs/operators';
import { User } from '../model/User';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    public dattaConfig: any;
    public user: User;

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    }
    private currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<any>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject(JSON.parse(sessionStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
        this.dattaConfig = DattaConfig.config;
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(username: string, password: string) {
        return this.http.post(this.dattaConfig['httplink'] + '/auth', { username, password })
            .pipe(map(user => {
                // store user details and basic auth credentials in local storage to keep user logged in between page refreshes
                // user.authdata = window.btoa(username + ':' + password);
                //localStorage.setItem('currentUser', JSON.stringify(user));
                sessionStorage.setItem('currentUser', JSON.stringify(user));
                this.currentUserSubject.next(user);
                localStorage.setItem('logged-in', 'true');
                sessionStorage.setItem('logged-in', 'true');
                localStorage.removeItem('logout');
                // console.log(user);
                return user;
            }));
    }

    loginLGSP(username: string, password: string) {
        return this.http.post(this.dattaConfig['httplink'] + '/login-lgsp', { username, password })
            .pipe(map(user => {
                // store user details and basic auth credentials in local storage to keep user logged in between page refreshes
                // user.authdata = window.btoa(username + ':' + password);
                //localStorage.setItem('currentUser', JSON.stringify(user));
                sessionStorage.setItem('currentUser', JSON.stringify(user));
                this.currentUserSubject.next(user);
                localStorage.setItem('logged-in', 'true');
                sessionStorage.setItem('logged-in', 'true');
                localStorage.removeItem('logout');
                // console.log(user);
                return user;
            }));
    }
    
    logout() {
        sessionStorage.removeItem('currentUser');
        sessionStorage.removeItem('logged-in');
        localStorage.setItem('logout', 'true');
        localStorage.removeItem('logged-in');       
        this.currentUserSubject.next(null);
    }
}